module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RBL Art Designs - Tech for the people. Web development and tech consulting.","short_name":"RBL Art Designs","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"static/assets/logo-idea1-150px-whiteshadow.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8989d0446de070ea5d4f09a6dfc4c767"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://m.rblartdesign.com","siteUrl":"https://rblartdesign.com","matomoJsScript":"stats.js","matomoPhpScript":"stats.php","disableCookies":true,"respectDnt":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
